import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { QuickbookLogin } from '@/components/shared'

export default function Home() {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} justify="center" alignItems="center">
        <Typography variant="h5">
          Welcome to Jackie's tools!
          <QuickbookLogin />
        </Typography>
      </Grid>
    </Grid>
  )
}
